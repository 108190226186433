import React, { createContext, useState } from 'react'
import PropTypes from 'prop-types'

export const AppContext = createContext({
  publicKey: "",
  walletType: null,
  sellingAssetCode: "XLM",
  sellingAssetIssuer: "native",
  buyingAssetCode: "USDC",
  buyingAssetIssuer: "GA5ZSEJYB37JRC5AVCIA5MOP4RHTM335X2KGX3IHOJAPP5RE34K4KZVN",
  setPublicKey: (publicKey) => {},
  setWalletType: (type) => {},
  setSellingAsset: (code, issuer) => {},
  setBuyingAsset: (code, issuer) => {}
});

const AppContextProvider = ({children}) => {
  const setPublicKey = (key) => {
    setAppContext(prevState => {
      return {...prevState, "publicKey": key}
    });
  }

  const setWalletType = (type) => {
    setAppContext(prevState => {
      return {...prevState, "walletType": type}
    });
  }

  const setSellingAsset = (code, issuer) => {
    setAppContext(prevState => {
      return {...prevState, "sellingAssetCode": code, "sellingAssetIssuer": issuer}
    });
  }

  const setBuyingAsset = (code, issuer) => {
    setAppContext(prevState => {
      return {...prevState, "buyingAssetCode": code, "buyingAssetIssuer": issuer}
    });
  }

  const [appContext, setAppContext] = useState({
    "publicKey": "",
    "walletType": null,
    "sellingAssetCode": "XLM",
    "sellingAssetIssuer": "native",
    "buyingAssetCode": "USDC",
    "buyingAssetIssuer": "GA5ZSEJYB37JRC5AVCIA5MOP4RHTM335X2KGX3IHOJAPP5RE34K4KZVN",
    "setPublicKey": setPublicKey,
    "setWalletType": setWalletType,
    "setSellingAsset": setSellingAsset,
    "setBuyingAsset": setBuyingAsset,
  });

  return(
    <AppContext.Provider value={appContext}>
      {children}
    </AppContext.Provider>
  );
}

AppContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default AppContextProvider