import React, { useContext } from "react"
import { AppContext } from "../../context/AppContextProvider";
import styles from "./NavLinks.module.css"
import { NavLink } from "react-router-dom";

const NavLinks = ({vertical, linkStyle, onSelection = null, tabIndex = 0}) => {
  const style = linkStyle ? `${linkStyle}` : styles.link;
  const { sellingAssetCode, sellingAssetIssuer, buyingAssetCode, buyingAssetIssuer } = useContext(AppContext);

  return (
    <nav>
      <ul className={vertical ? styles.containerVertical : styles.containerHorizontal}>
        <li>
          <NavLink
            to={`/trading/${sellingAssetCode}:${sellingAssetIssuer}/${buyingAssetCode}:${buyingAssetIssuer}`}
            className={style}
            onClick={onSelection}
            tabIndex={tabIndex}
          >
            SDEX Trading
          </NavLink>
        </li>
        <li>
          <NavLink
            to={`/swap/${sellingAssetCode}:${sellingAssetIssuer}/${buyingAssetCode}:${buyingAssetIssuer}`}
            className={style}
            onClick={onSelection}
            tabIndex={tabIndex}
          >
            Swap
          </NavLink>
        </li>
        <li>
          <NavLink
            to={`/liquidity-pools/${sellingAssetCode}:${sellingAssetIssuer}/${buyingAssetCode}:${buyingAssetIssuer}`}
            className={style}
            onClick={onSelection}
            tabIndex={tabIndex}
          >
            Liquidity Pools
          </NavLink>
        </li>
        <li>
          <NavLink to="/aqua-tools" className={style} onClick={onSelection} tabIndex={tabIndex}>
            AQUA Tools
          </NavLink>
        </li>
        <li>
          <NavLink to="/pom-token" className={style} onClick={onSelection} tabIndex={tabIndex}>
            POM Token
          </NavLink>
        </li>
      </ul>
    </nav>
  )
};

export default NavLinks;
