import stellarLogo from '../images/tokens/xlm.png'

export const knownAssets = {
  "native": {
    home_domain: "stellar.org",
    "XLM": stellarLogo,
  },
  "GBX6YI45VU7WNAAKA3RBFDR3I3UKNFHTJPQ5F6KOOKSGYIAM4TRQN54W": {
    home_domain: "afreum.com",
    "AFR": "https://afreum.com/stellar/ST_Afreum.png",
  },
  "GALLBRBQHAPW5FOVXXHYWR6J4ZDAQ35BMSNADYGBW25VOUHUYRZM4XIL": {
    home_domain: "allbridge.io",
    "abBNB": "https://allbridge-assets.web.app/200px/bnb.png",
    "abUSDC": "https://allbridge-assets.web.app/200px/usdc.png",
    "acCELO": "https://allbridge-assets.web.app/200px/celo.png",
    "aeDAI": "https://allbridge-assets.web.app/200px/dai.png",
    "aeETH": "https://allbridge-assets.web.app/200px/eth.png",
    "aeUSDC": "https://allbridge-assets.web.app/200px/usdc.png",
    "apMATIC": "https://allbridge-assets.web.app/200px/matic.png",
    "apUSDC": "https://allbridge-assets.web.app/200px/usdc.png",
    "asSOL": "https://allbridge-assets.web.app/200px/sol.png",
    "asUSDC": "https://allbridge-assets.web.app/200px/usdc.png",
  },
  "GAX5TXB5RYJNLBUR477PEXM4X75APK2PGMTN6KEFQSESGWFXEAKFSXJO": {
    home_domain: "app.phoenix-hub.io",
    "PHO": "https://app.phoenix-hub.io/logoIcon.png",
  },
  "GBNZILSTVQZ4R7IKQDGHYGY2QXL5QOFJYQMXPKWRRM5PAV7Y4M67AQUA": {
    home_domain: "aqua.network",
    "AQUA": "https://aqua.network/assets/img/aqua-logo.png",
  },
  "GDJEHTBE6ZHUXSWFI642DCGLUOECLHPF3KSXHPXTSTJ7E3JF6MQ5EZYY": {
    home_domain: "blend.capital",
    "BLND": "https://assets-global.website-files.com/63ff7d58715f3d565376d770/663bae100d4b2808b14b0bf4_blend%20token%20-%20color%20%403x.png",
  },
  "GA5ZSEJYB37JRC5AVCIA5MOP4RHTM335X2KGX3IHOJAPP5RE34K4KZVN": {
    home_domain: "centre.io",
    "USDC": "https://www.centre.io/images/usdc/usdc-icon-86074d9d49.png",
  },
  "GDHU6WRG4IEQXM5NZ4BMPKOXHW76MZM4Y2IEMFDVXBSDP6SJY4ITNPP2": {
    home_domain: "circle.com",
    "EURC": "https://www.circle.com/hubfs/Brand/EURC/EURC-icon_128x128.png",
  },
  "GAORYJ3KBDGIM7FFSKVUJHJ5NEFWIRDIAGGBJBJS7TY6ECZS53257IG4": {
    home_domain: "dogstarcoin.com",
    "XTAR": "https://www.dogstarcoin.com/assets/img/dogstarcoin-logo.png",
  },
  "GBXRPL45NPHCVMFFAYZVUVFFVKSIZ362ZXFP7I2ETNQ3QKZMFLPRDTD5": {
    home_domain: "fchain.io",
    "XRP": "https://files.fchain.io/token-icons/xrp-logo.png",
  },
  "GCA73U2PZFWAXJSNVMEVPNPPJCZGETWPWZC6E4DJAIWP3ZW3BAGYZLV6": {
    home_domain: "fredenergy.org",
    "FRED": "https://fredenergy.org/fred-logo-xlm.png",
  },
  "GAB7STHVD5BDH3EEYXPI3OM7PCS4V443PYB5FNT6CFGJVPDLMKDM24WK": {
    home_domain: "lumenswap.io",
    "LSP": "https://lumenswap.io/t/Lumenswap144.png",
  },
  "GBVB43NLVIP2USHXSKI7QQCZKZU2Z6U6A5PAHMIW7LLNVMQJTOX2BZI5": {
    home_domain: "luxpayband.io",
    "PYBC": "https://luxpayband.io/img/LXLogo.png",
  },
  "GBC5ZGK6MQU3XG5Y72SXPA7P5R5NHYT2475SNEJB2U3EQ6J56QLVGOLD": {
    home_domain: "mintx.co",
    "GOLD": "https://www.mintx.co/images/logo100.png",
  },
  "GBZVELEQD3WBN3R3VAG64HVBDOZ76ZL6QPLSFGKWPFED33Q3234NSLVR": {
    home_domain: "mintx.co",
    "SLVR": "https://www.mintx.co/images/logo-silver100.png",
  },
  "GD5QHIRQKDT7NOX63VEA6OFXZXGN34O7WGMYL4GYWEGMDOMC43HHIPOM": {
    home_domain: "pomdex.io",
    "POM": "https://pomdex.io/images/PomLogo_128x128.png",
  },
  "GCHI6I3X62ND5XUMWINNNKXS2HPYZWKFQBZZYBSMHJ4MIP2XJXSZTXRF": {
    home_domain: "reflector.network",
    "XRF": "https://reflector.network/images/logo.svg",
  },
  "GC6OYQJIZF3HFXCYPFCBXYXNGIBQ4TNSFUBUXQJOZWIP6F3YZK4QH3VQ": {
    home_domain: "scopuly.com",
    "SCOP": "https://scopuly.com/img/logo/icon.png",
  },
  "GBUYYBXWCLT2MOSSHRFCKMEDFOVSCAXNIEW424GLN666OEXHAAWBDYMX": {
    home_domain: "script3.io",
    "YBX": "https://uploads-ssl.webflow.com/607f603d2f412c67690368b8/60df683719cc056004ea932b_YBX%20token%20web%20bad.png",
  },
  "GBVOL67TMUQBGL4TZYNMY3ZQ5WGQYFPFD5VJRWXR72VA33VFNL225PL5": {
    home_domain: "stellarport.io",
    "BTC": "https://stellarport.io/assets/issues/btc.png",
  },
  "GDSTRSHXHGJ7ZIVRBXEYE5Q74XUVCUSEKEBR7UCHEUUEK72N7I7KJ6JH": {
    home_domain: "stronghold.co",
    "SHX": "https://stronghold.co/.well-known/logo.png",
  },
  "GDGTVWSM4MGS4T7Z6W4RPWOCHE2I6RDFCIFZGS3DOA63LWQTRNZNTTFF": {
    home_domain: "ultracapital.xyz",
    "yUSDC": "https://ultracapital.xyz/static/images/icons/yUSDC.png",
  },
  "GARDNV3Q7YGT4AKSDF25LT32YSCCW4EV22Y2TV3I2PU2MMXJTEDL5T55": {
    home_domain: "ultracapital.xyz",
    "yXLM": "https://ultracapital.xyz/static/images/icons/yXLM.png",
  },
  "GDYQNEF2UWTK4L6HITMT53MZ6F5QWO3Q4UVE6SCGC4OMEQIZQQDERQFD": {
    home_domain: "ultracapital.xyz",
    "yETH": "https://ultracapital.xyz/static/images/icons/yETH.png",
  },
  "GBUVRNH4RW4VLHP4C5MOF46RRIRZLAVHYGX45MVSTKA2F6TMR7E7L6NW": {
    home_domain: "ultracapital.xyz",
    "yBTC": "https://ultracapital.xyz/static/images/icons/yBTC.png",
  },
  "GBFXOHVAS43OIWNIO7XLRJAHT3BICFEIKOJLZVXNT572MISM4CMGSOCC": {
    home_domain: "ultracapital.xyz",
    "ETH": "https://ultracapital.xyz/static/images/icons/ETH.png",
  },
  "GDPJALI4AZKUU2W426U5WKMAT6CN3AJRPIIRYR2YM54TL2GDWO5O2MZM": {
    home_domain: "ultracapital.xyz",
    "BTC": "https://ultracapital.xyz/static/images/icons/BTC.png",
  },
};