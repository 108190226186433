import React from 'react'
import PropTypes from 'prop-types'
import styles from "./ExternalLink.module.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'

const ExternalLink = ({href, text, showLinkIcon}) => {
  return(
    <span className={styles.container}>
      <a
        className={styles.link}
        target="_blank"
        href={href}
        rel="noopener noreferrer"
      >
        <span>
          {text}
          {showLinkIcon && <FontAwesomeIcon className={styles.linkIcon} icon={faExternalLinkAlt} alt="(opens in new tab)" />}
        </span>
      </a>
    </span>
  );
}

ExternalLink.propTypes = {
  href: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  showLinkIcon: PropTypes.bool,
}

export default ExternalLink