import { React } from "react";
import styles from "./POMToken.module.css"
import ExternalLink from '../../components/ExternalLink/ExternalLink.js';
import { NavLink } from "react-router-dom";

const pomAssetCode = "POM"
const pomAssetIssuer = "GD5QHIRQKDT7NOX63VEA6OFXZXGN34O7WGMYL4GYWEGMDOMC43HHIPOM"
const pomAssetDistributor = "GAUZMJI5BXW7XVSODUXHJYXBR2Y2H4PEZHV6MWCP57RSAFMHWJKI32MA"
const usdcAssetCode = "USDC"
const usdcAssetIssuer = "GA5ZSEJYB37JRC5AVCIA5MOP4RHTM335X2KGX3IHOJAPP5RE34K4KZVN"

const POMToken = () => {
  return (
    <div className={styles.pomTokenModule}>
      <h1>POM Token</h1>
      <div className={styles.infoSection}>
        <p>We have created the POM token as a bit of an economic experiment, with the hope of generating ongoing revenue to incentivize further development of PomDEX.</p>
        <p>PomDEX is an Aquarius-focused Dapp, and we want to see if the AQUA ecosystem can be used to help keep the lights on, while also providing value for people who choose to vote for our POM/USDC pair and provide liquidity.</p>
        <p>We have placed a sell order of POM tokens up on the Stellar SDEX POM/USDC pair at $1.01 USDC, a 1% spread from our buyback price. We may lower this spread in the future. The community can use those tokens to provide liquidity on either the SDEX or classic Stellar liquidity pools to earn AQUA rewards. If things go well, we will also set up a Soroban pool through Aquarius.</p>
        <p>There is, and will always be, a standing offer on the SDEX POM/USDC pair with enough USDC to purchase back all circulating POM tokens at $1.</p>
        <p>We will only have offers up on the SDEX, and will not enter the liquidity pools at all, other than the initial $1 used to create the pool.</p>
        <p>Liquidity pools are yours to farm for AQUA rewards.</p>
      </div>
      <h2>Links</h2>
      <div className={styles.accounts}>
        <NavLink
            to={`/trading/${pomAssetCode}:${pomAssetIssuer}/USDC:${usdcAssetIssuer}`}
            className={styles.link}
            // onClick={onSelection}
            // tabIndex={tabIndex}
        >
          POM/USDC SDEX Trading Pair
        </NavLink>
        <NavLink
            to={`/liquidity-pools/${pomAssetCode}:${pomAssetIssuer}/${usdcAssetCode}:${usdcAssetIssuer}`}
            className={styles.link}
            // onClick={onSelection}
            // tabIndex={tabIndex}
        >
          POM/USDC Classic Liquidity Pool
        </NavLink>
        {/* <ExternalLink
          text={"POM/USDC Aquarius Soroban Pool"}
          href={"ADD AQUARIUS SOROBAN POOL LINK"}
          showLinkIcon={true}
        /> */}
        <ExternalLink
          text={"Aquarius Voting Page"}
          href={`https://aqua.network/market/${pomAssetCode}:${pomAssetIssuer}/${usdcAssetCode}:${usdcAssetIssuer}`}
          showLinkIcon={true}
        />
        <ExternalLink
          text={"Issuing Account - GD5QH.....HIPOM"}
          href={`https://stellar.expert/explorer/public/account/${pomAssetIssuer}`}
          showLinkIcon={true}
        />
        <ExternalLink
          text={"Distribution Account - GAUZM.....I32MA"}
          href={`https://stellar.expert/explorer/public/account/${pomAssetDistributor}`}
          showLinkIcon={true}
        />
        <ExternalLink
          text={"POM Token Information"}
          href={`https://stellar.expert/explorer/public/asset/${pomAssetCode}-${pomAssetIssuer}`}
          showLinkIcon={true}
        />
      </div>
    </div>
  );
}

export default POMToken;